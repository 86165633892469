import React, { Component } from "react";
import { Formik, Form as FormikForm } from "formik";
import yup from "yup";
import TextareaAutosize from "react-autosize-textarea";
import styled from "styled-components";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faPaperPlane from "@fortawesome/fontawesome-free-regular/faPaperPlane";
import faCircleNotch from "@fortawesome/fontawesome-free-solid/faCircleNotch";
import faPaperclip from "@fortawesome/fontawesome-free-solid/faPaperclip";

import { AppContext } from "./index.js";

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  background: transparent !important;
  resize: none;
  border: none;
  overflow: hidden;
  padding: 12px 20px 12px 10px !important;
  height: 50px !important;
  color: ${({ theme }) => theme.darkGray};
  @media only screen and (hover: none) and (pointer: coarse) {
    font-size: 16px; // desativar zoom-in do browser mobile na caixa
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  box-shadow: 0px -2px 6px 2px rgba(0,0,0,0.125);
  @media only screen and (hover: none) and (pointer: coarse) {
    box-shadow: 0px -2px 6px 2px rgba(0,0,0,0.175);
  }
`;

const FileUpload = styled.div`
display: flex;
justify-content: center;
`;

const Btn = styled.button.attrs({
  type: "submit"
})`
  width: 15%;
  padding: 5px;
  border: none;
  outline: none;
  background: none;
  color: ${({ theme }) => theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform-origin: 50% 50%;
  transition: all 120ms ease;
  &:hover, &:focus {
    opacity: 0.7;
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1.07);
  }
  &:disabled {
    filter: saturate(0%);
    transform: scale(1) !important;
    opacity: 1 !important;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
    margin-top: -2px;
    color: ${({ theme }) => theme.primaryColor}
  }
`;

const FileInputBtn = styled.button`
  width: 15%;
  padding: 5px;
  border: none;
  outline: none;
  background: none;
  color: ${({ theme }) => theme.darkGray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform-origin: 50% 50%;
  transition: all 120ms ease;
  &:hover, &:focus {
    opacity: 0.7;
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1.07);
  }
  &:disabled {
    filter: saturate(0%);
    transform: scale(1) !important;
    opacity: 1 !important;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
    margin-top: -2px;
    color: ${({ theme }) => theme.primaryColor}
  }
`;

const defaultMessage = { message: "" };
const messageSchema = yup.object().shape({
  message: yup.string().required()
});

const FileUploader = ({appendFile, disableButton}) => {
  const hiddenFileInput = React.createRef();

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];

    if (fileUploaded && fileUploaded.size > 100000000 ){ // se ficheiro maior que 100mb
      alert("Ficheiro demasiado grande! Máximo: 100Mb")
      return;
    }

    if (!fileUploaded){
      return;
    }
    else{
      appendFile(fileUploaded);
    }
  };
  return (
    <FileUpload>
      <FileInputBtn onClick={handleClick} disabled={disableButton} title={"Enviar Ficheiro"}>
        <FontAwesomeIcon
          icon={faPaperclip}
        />
      </FileInputBtn>
      <input type= "file"
            name= "file"
            id= "file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{display:'none'}}
            accept = "image/*,audio/*,video/*,.pdf,.docx,.doc,.xls,.xlsx,.ppt,.pptx,.txt"
      />
    </FileUpload>
  );
};

export default () => (
  <AppContext.Consumer>
    {context => {
      if (!context.isOpen) {
        return null;
      }

      return <Form {...context} />;
    }}
  </AppContext.Consumer>
);

class Form extends Component {
  _sendMessage = (values, { setValues, setSubmitting }) => {
    if (!this.props.error) {
      // remover new lines no início e no fim
      const msg = values.message.replace(/^\s+|\s+$/g, "");

      if (msg) {
        this.props.appendMessage({
          text: msg,
          outbound: true,
          sentAt: new Date()
        });
        setValues(defaultMessage);
      }
    }

    setSubmitting(false);
  };

  render() {
    return (
      <Formik
        initialValues={defaultMessage}
        validationSchema={messageSchema}
        onSubmit={this._sendMessage}
        render={formikProps => <InnerForm {...this.props} {...formikProps} />}
      />
    );
  }
}

class InnerForm extends Component {
  state = {
    emptyField: true,
    composeIntent: false
  }

  _textareaRef = textarea => {
    this.textarea = textarea;
  };

  _handleKeyUp = (event) => {
    const { submitForm } = this.props;
    const isEnter = event.keyCode == 13;

    if (event.shiftKey && isEnter) {
      event.stopPropagation();

      const content = event.target.value;
      const caret = getCaret(this.textarea);
      const value = `${content.substring(0, caret)}\n${content.substring(
        caret,
        content.length - 1
      )}`;

      this.textarea.value = value;
    } else if (isEnter) {
      event.preventDefault();
      event.stopPropagation();
      submitForm();

      // Desativar o botão de enviar novamente e forçar o foco
      // na entrada de texto (em mobile não há nada que o faça)
      // Lógica: não focar ao abrir a coversa (estilo FB Messenger)
      // Queremos manter focado após enviar uma mensagem
      this.setState({
        emptyField: true,
        composeIntent: true
      });
    }
  };

  _handleChange = event => {
    const { setFieldValue } = this.props;
    setFieldValue("message", event.target.value);
    event.target.value.length == 0 ?
      this.setState({ emptyField: true })
    : this.setState({ emptyField: false })
  };

  // Se não estivermos em mobile -> o teclado ocupa uma porção do ecrã significativa
  componentDidUpdate() {
    if (window.matchMedia('(hover: none) and (pointer: coarse)').matches) {
      if (this.state.composeIntent) {
        this.textarea.textarea.focus();
      }
    } else {
      this.textarea.textarea.focus();
    }
  }

  componentDidMount() {
    if (window.matchMedia('(hover: none) and (pointer: coarse)').matches) {
      if (this.state.composeIntent) {
        this.textarea.textarea.focus();
      }
    } else {
      this.textarea.textarea.focus();
    }
  }

  render() {
    const { error, values, isSubmitting, appendFile, operatorUserInfo, hasFileUpload } = this.props;

    let disableButton;
    if(operatorUserInfo){
      disableButton = false;
    }else{
      disableButton = true;
    }

    return (
      <FormikForm style={{background: "#f5f5f5"}}>
        <Container>
          <Textarea
            innerRef={this._textareaRef}
            ref={(input) => { this.nameInput = input; }}
            id="message"
            name="message"
            rows={window.matchMedia('(hover: none) and (pointer: coarse)').matches ? 1 : 2}
            maxRows={3}
            style={{height: "50px !important", fontSize: "16px"}}
            value={values.message}
            disabled={isSubmitting || !!error}
            placeholder="Escreva a sua mensagem..."
            onChange={this._handleChange}
            onKeyUp={this._handleKeyUp}
          />
          {hasFileUpload && (
            <FileUploader
              appendFile={appendFile}
              disableButton = {disableButton}
            />)
          }
          <Btn disabled={isSubmitting || this.state.emptyField || !!error} title="Enviar mensagem">
            <FontAwesomeIcon
              spin={isSubmitting}
              icon={isSubmitting ? faCircleNotch : faPaperPlane}
            />
          </Btn>
        </Container>
      </FormikForm>
    );
  }
}
