import React, { Component } from "react";
import styled from "styled-components";

import Message from "./Message.js";

import { AppContext } from "./index.js";
import ConnError from "./ConnError.js";


const Container = styled.div`
  width: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column-reverse;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.white};
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${({ theme }) => theme.darkGray};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.darkGray};
  }
`;

const StyledList = styled.div`
  bottom: 70px;
  padding-left: 8px;
  padding-right: 8px;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.outbound ? "flex-end" : "flex-start")};
`;

export default () => (
  <AppContext.Consumer>
    {context => {
      if (!context.isOpen) {
        return null;
      }

      return <Messages {...context} />;
    }}
  </AppContext.Consumer>
);

class Messages extends Component {
  _listRef = div => {
    this.scrollContainer = div;
  };

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.messages.length < this.props.messages.length) {
      return true;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
    }
  }

  render() {
    const { error, messages, botName, imageUrl, appendMessage, apiUrl } = this.props;

    return (
      <React.Fragment>
        <Container>
          <StyledList innerRef={this._listRef}>
            {messages.map((msg, index) => (
              <MsgContainer key={`msg-${index}`} outbound={msg.outbound}>
                <Message
                  outbound={msg.outbound}
                  message={msg}
                  botName={botName}
                  imageUrl={imageUrl}
                  apiUrl={apiUrl}
                  appendMessage={appendMessage}
                />
              </MsgContainer>
            ))}
          </StyledList>
        </Container>
        <ConnError>{error}</ConnError>
      </React.Fragment>
    );
  }
}
