import React, { Component } from "react";

export default class PureCanvas extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <canvas
        width="640px"
        height="360px"
        id='placeHolderCanvasAnimation'
        ref={node =>
          node ? this.props.contextRef(node.getContext('2d')) : null
        }
      />
    );
  }
}
