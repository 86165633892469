const defaultTheme = {
  white: "#ffffff",
  black: "#000000",
  gray: "#7a8f9a",
  darkGray: "#546269",
  lightGray: "#e4e8e9",
  errorColor: "#ff1c05",
  primaryColor: "#1e90ff",
  secondaryColor: "#70a1ff",
  alertColor: "#ff6348",
  lightAlertColor: "#ff7f50",
  lightErrorColor: "#ffdcd9",
  lightPrimaryColor: "#e3f1ff",
  successColor: "#2ed573"
};

export default defaultTheme;
