import React, { Component } from "react";

import PureCanvas from "./PureCanvas";

export default class PlaceHolderCanvas extends Component {
  constructor(props) {
    super(props);
    this.saveContext = this.saveContext.bind(this);
  }

  saveContext(ctx) {
    this.ctx = ctx;
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;
  }

  componentDidUpdate() {

    this.ctx.save();
    this.ctx.clearRect(0, 0, this.width, this.height);

    this.ctx.font = `${this.props.textSize}px Arial`;
    this.ctx.fillStyle = "red";
    this.ctx.fillText("*", 600, 340);

    this.ctx.font = `40px Arial`;
    this.ctx.fillStyle = "#ffffff";

    const textWidth = this.ctx.measureText(this.props.text).width;

    this.ctx.fillText(this.props.text, (640 / 2) - (textWidth / 2), 180);
    this.ctx.restore();
  }

  render() {
    return <PureCanvas contextRef={this.saveContext} />;
  }
}
