import React, { Component } from 'react'
import styled from "styled-components";
import { injectGlobal } from "styled-components";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/fontawesome-free-solid";

injectGlobal`
  #aida-mount .text-message a {
    color: ${({ theme, outbound, isBot }) =>
      outbound ? theme.white : isBot ? theme.darkGray : theme.white} !important;
    text-decoration: underline !important;
    font-weight: 700 !important;
    transition: opacity 150ms ease;
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      opacity: 0.8;
    }
  }

  #aida-mount .aida-quickaction {
    cursor: pointer;
    display: inline-block;
    padding: 3px 4px;
    border-radius: 3px;
    background: rgba(0,0,0,0.1);
    font-weight: 900;
    margin: 3px 0;
    transition: background 150ms ease;
    &:hover,
    &:active,
    &:focus {
      background: rgba(0,0,0,0.15);
    }
  }
`;

const MasterContainer = styled.div`
  margin: 12px 0;
  word-wrap: break-word;
  max-width: 90%;
`;

  const MessageItem = styled.div`
  font-size: 18px;
  line-height: 1.4375rem;
  border-radius: 8px;
  padding: 6px 12px;
  color: ${({ theme, outbound, isBot }) =>
    outbound ? theme.white : isBot ? theme.darkGray : theme.white};
  background-color: ${({ theme, outbound, isBot }) =>
    outbound ? theme.primaryColor : isBot ? theme.lightGray : theme.darkGray};
  a {
    color: ${({ theme, outbound, isBot }) =>
      outbound ? theme.white : isBot ? theme.darkGray : theme.white} !important;
    text-decoration: underline !important;
    font-weight: 700 !important;
    transition: opacity 150ms ease;
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      opacity: 0.8;
    }
  }
  p, div, span, section {
    margin: 0;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  cursor: default;
`;

const UserName = styled.span`
  font-weight: 600;
  display: block;
  font-size: 14px;
  line-height: 14px;
  cursor: default;
`;

const UserImg = styled.img`
  display: block;
  height: 28px;
  width: 28px;
  margin-right: 8px;
  border-radius: 28px;
  background: #e9e9e9;
  border: 0;
  outline: 0;
  cursor: default;
  overflow: hidden;
  box-shadow: 0 2px 4px -2px rgba(0,0,0,0.275);
`

const MessageTime = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 13px;
  line-height: 13px;
  text-align: right;
  color: #666;
  cursor: help;
`
const customTrim = (s, c) => {
  if (c === "]") c = "\\]";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp(
    "^[" + c + "]+|[" + c + "]+$", "g"
  ), "");
}

export default class Message extends Component {
  urlify = text => {
    var urlMarkdownRegex = /\[(.*?)\]\((.*?)\)/g;
    if (urlMarkdownRegex.test(text)) {
      return text.replace(urlMarkdownRegex, function(match, link_text, url) {
        return '<div><a target="_blank" href="' + url + '">' + link_text + "</a></div>";
      });
    }

    var urlRegex = /((http|https)?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<div><a target="_blank" href="' + url + '">' + url + "</a></div>";
    });
  };

  clickHandler = (e) => {
    const { appendMessage } = this.props;
    // `target` is the element the click was on (the div we hooked or an element
    // with in it), `currentTarget` is the div we hooked the event on
    const el = e.target.closest(".aida-quickaction");
    if (el && e.currentTarget.contains(el)) {
        const trimedAction = customTrim(el.innerText.trim(),"\"");
        appendMessage({
          text:trimedAction,
          outbound: true,
          sentAt: new Date()
        });
    }
  }

  render() {
    const { message, imageUrl, botName='Robot', apiUrl } = this.props;
    const messageDate = new Date(message.sentAt);

    const parsedTime = messageDate.getHours().toString().padStart(2, '0') + ":" + messageDate.getMinutes().toString().padStart(2, '0');
    const elapsedParsedTime = messageDate.getFullYear() + '-' + (messageDate.getMonth() + 1).toString().padStart(2, '0') + '-' + messageDate.getDate().toString().padStart(2, '0') + ' ' + parsedTime;

    const normalizedImage = (
      imageUrl
        ? imageUrl
        : "https://image.flaticon.com/icons/svg/682/682037.svg"
    );

    return (
      <MasterContainer>
        <UserInfo>
          {!message.outbound && <UserImg src={message.isBot ? normalizedImage : message.operatorUserPhoto} alt={message.operatorUserName} /> }
          <UserName
            children={message.outbound ? "Eu" : message.isBot ? botName : message.operatorUserName}
            style={{marginLeft: message.outbound && "auto"}}
          />
        </UserInfo>
        {!message.fileUrl && (
          <MessageItem className="text-message" outbound={message.outbound} isBot={message.isBot} onClick={this.clickHandler} dangerouslySetInnerHTML={{
            __html: this.urlify(
              message.text
                          .replace(/(?:\r\n|\r|\n|\\n)/g, "<br>")
                          .replace(/(\<script\>)/g, "\<script\>")
                          .replace(/(\<\/script\>)/g, "\<\/script\>")
                          .replace(/(\<action\>)/g, "\<span class='aida-quickaction'>")
                          .replace(/(\<\/action\>)/g, "\<\/span\>")
        )}} />
        )}{message.fileUrl && (
          <MessageItem className="text-message" outbound={message.outbound} isBot={message.isBot} onClick={this.clickHandler} >
            <div><a target="_blank" href={apiUrl + message.fileUrl}><FontAwesomeIcon icon={faPaperclip}/> {message.text}</a></div>
          </MessageItem>
        )}
        <MessageTime children={parsedTime} title={elapsedParsedTime}/>
      </MasterContainer>
    )
  }
}
