import React, { Component } from "react";
import { PoseGroup } from "react-pose";
import { ThemeProvider } from "styled-components";
import detect from "detect.js";
import io from "socket.io-client";

import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';

import Form from "./Form";
import Avatar from "./Avatar";
import Messages from "./Messages";

import { ChatHeader, Container, Slab, SlabTitle, SlabSubTitle, VideoConfirmation, ActiveUsers, IconContainer, VideoChatContainer, LocalVideoControls, LocalVideoConfig, CallButtonContainer, BetaTag, UserHangUp , UserShare } from "./StyledComponents";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faUserCircle, faVideo, faVideoSlash, faVolumeOff, faVolumeUp, faMicrophone, faPhone, faDesktop, faPhoneSlash, faMicrophoneSlash, faCog, faTimes, faExclamationCircle } from "@fortawesome/fontawesome-free-solid";

import VideoCall from '../../common/helpers/simple-peer';
import PlaceHolderCanvasAnimation from '../../common/components/PlaceHolderCanvasAnimation';
import Terms from "./Terms";

import ringtoneSound from "../assets/ringtone.mp3";
import hangupSound from "../assets/hangup.mp3";

export const STORAGE_USER_KEY = "__AIDA_CLIENT_USER__";
export const MAX_OLD_MESSAGES_SAVED = 30;

const isDev = process.env.NODE_ENV === "development";

// console.log("isDev", isDev);

// Testar Safari Mobile (iPad ou iPhone)
const isSafari = window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i);

const initialState = {
  roomId: null,
  messages: [
    {
      text: "Olá, o meu nome é AIDA e sou uma assistente virtual.",
      outbound: false,
      sentAt: new Date()
    }
  ],
  area: null,
  error: false,
  isOpen: false,
  browserInfo: {},
  isHovering: false,
  waitingForVideo: false,
  connectingVideo: false,
  videoChatConnected: false,
  userIsStreaming: false,
  askForMicPermission: false
};

export const AppContext = React.createContext(initialState);

let ringtone = new Audio();
let hangupRingtone = new Audio();

export default class AidaClient extends Component {
  constructor(props) {
    super(props);

    this.localVideoRef = React.createRef();
    this.remoteStreamRefs = [];

    const queryParams = new URLSearchParams(window.location.search);
    this.joiningRoomId = queryParams.get('joinRoomId');

    this.currentUserId = this.joiningRoomId ? -1 : this.props.currentUserId;

    // Definir toque de chamada
    ringtone.src = isDev ? this.props.apiUrl + ringtoneSound.replace('/ringtone', '/aida/cdn/aida-client/ringtone') : this.props.apiUrl + ringtoneSound.replace('ringtone', '/aida/cdn/aida-client/ringtone');
    ringtone.loop = true;

    // Definir toque de fim de chamada
    hangupRingtone.src = isDev ? this.props.apiUrl + hangupSound.replace('/hangup', '/aida/cdn/aida-client/hangup') : this.props.apiUrl + hangupSound.replace('hangup', '/aida/cdn/aida-client/hangup');
    hangupRingtone.loop = false;

    this.state  = {
      roomId: this.getLocalStorage() ? this.getLocalStorage().roomId : null,
      messages: (
                  this.getLocalStorage() && this.getLocalStorage().messages ?
                    this.getLocalStorage().messages :
                    [
                      {
                        text: this.props.presentationMsg,
                        outbound: false,
                        sentAt: new Date(),
                        isBot: true
                      }
                    ]
                ),
      error: (this.joiningRoomId != null ? "A ligar ao servidor..." : false),
      isOpen: this.joiningRoomId != null,
      browserInfo: {},
      isHovering: false,
      numberOfActiveOperators: 0,
      operatorUserInfo: null,

      localStream: {},
      remoteStreams: {},
      localDisplayStream: {},
      streamType: '',
      boStreamType: '',
      peers: [{}],

      waitingForVideo: false,
      connectingVideo: false,
      videoChatConnected: false,
      userIsStreaming: false,

      mediaDevicesList: [],
      audioInputDeviceId: 0,
      videoInputDeviceId: 0,
      audioOutputDeviceId: 0,
      camState: true,
      micState: true
    };
  }

  videoCall = new VideoCall();

  componentDidUpdate() {
    if(this.localVideoRef && this.localVideoRef.current) {
      if (this.state.localStream.constructor.name == "MediaStream") {
        if (this.localVideoRef.current.srcObject != this.state.localStream && this.localVideoRef.current.srcObject != this.state.localDisplayStream) {
          this.localVideoRef.current.srcObject = this.state.localStream;
        }
      } else {
        this.localVideoRef = React.createRef();
      }
    }

    if(this.remoteStreamRefs.length > 0) {
      Object.entries(this.state.remoteStreams).forEach((remoteStream, index) => {
        if (remoteStream[1].constructor.name == "MediaStream") {
          if (this.remoteStreamRefs[index].srcObject != remoteStream[1]) {
            this.remoteStreamRefs[index].srcObject = remoteStream[1];
          }
        }
      });
    }
  }

  errorLog = (msg, error) => {
    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat) {
      if (data) {
        console.log("\x1b[31m%s\x1b[0m", "====================================\nDebugger => " + msg + ":\n", error);
        console.log("\x1b[31m%s\x1b[0m", "====================================");
      }
      else
        console.log("\x1b[31m%s\x1b[0m", "====================================\nDebugger => " + msg + "\n====================================");
    }
  }

  debugLog = (msg, data) => {
    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat) {
      if (data) {
        console.log("\x1b[35m%s\x1b[0m", "====================================\nDebugger " + msg + ":\n", data);
        console.log("\x1b[35m%s\x1b[0m", "====================================");
      }
      else
        console.log("\x1b[35m%s\x1b[0m", "====================================\nDebugger " + msg + "\n====================================");
    }
  }

  logSocketOn = (msg, data) => {
    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat) {
      if (data) {
        console.log("\x1b[36m%s\x1b[0m", "====================================\nSocket <= ON <= " + msg + ":\n", data);
        console.log("\x1b[36m%s\x1b[0m", "====================================");
      }
      else
        console.log("\x1b[36m%s\x1b[0m", "====================================\nSocket <= ON <= " + msg + "\n====================================");
    }
  }

  socketEmit = (msg, data) => {
    if (process.env.NODE_ENV === "development" || localStorage.debugWireChat) {
      console.log("\x1b[33m%s\x1b[0m\n", "====================================\nSocket => EMIT => " + msg + ":\n", data);
      console.log("\x1b[33m%s\x1b[0m", "====================================");
    }

    this.socket.emit(msg, data);
  }

  getLocalStorage = () => {
    //Expira em 8h
    if(!localStorage.getItem(STORAGE_USER_KEY))
      return {};
    else {
      const storage = JSON.parse(localStorage.getItem(STORAGE_USER_KEY));
      if(storage.messages && storage.messages.length > 0) {
        const currentDate = new Date();
        const lastDate = new Date(storage.messages.slice(-1)[0].sentAt||'');
        if(Math.abs(currentDate - lastDate) > 8*60 * 60 * 1000){
          localStorage.removeItem(STORAGE_USER_KEY);
          return {};
        }
      }
      return storage;
    }
  };

  writeToLocalStorage = data => {
    localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(data));
  };

  openChatModalClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    }, () => {
      if(this.state.isOpen && !this.socket){
        this.setState({
          error: "A ligar ao servidor..."
        }, this.setupSocket);
      }
    });
  };

  onMouseHover = isHovering => {
    this.setState({ isHovering });
  };

  appendMessage = msg => {
    const { error, roomId, messages, area } = this.state;
    const { appointmentInfo } = this.props;

    if (error) {
      return;
    }

    let currentUrl = null;
    try{ currentUrl = window.location.href; } catch(e){};
    let pageTitleContext = "";
    try{ pageTitleContext = document.querySelector("head title").text; } catch(e){};

    this.socketEmit("client-message", {
      text: msg.text,
      tenant: this.props.tenant,
      currentUserId: this.currentUserId,
      currentUrl,
      pageTitleContext,
      roomId: roomId,
      appointmentInfo,
      area
    });

    // Isto é para garantir que esta mensagem não é exibida aqui
    if (msg.text != 'requestingToJoinConversation!') {
      const newState = {
        messages: messages.concat([msg])
      };

      this.setState(newState, () => this.writeToLocalStorage(
        {
          roomId: this.state.roomId,
          messages: newState.messages
        }
      ));
    } else {
      this.writeToLocalStorage({ roomId: this.state.roomId });
    }
  };

  appendFile = file => {
    this.socketEmit("client-file", {
      file,
      file_name: file.name,
      tenant: this.props.tenant,
      currentUserId: this.currentUserId,
      roomId: this.state.roomId,
    });
  };
  handleSocketConnect = (useAppointments) => {
    this.debugLog("handleSocketConnect", useAppointments);
    const { appointmentInfo } = this.props;
    let { isOpen } = this.state;

    if(appointmentInfo && useAppointments)
      isOpen = true;

    this.setState({ error: false, isOpen });

    this.socketEmit("client-login", {
      roomId: this.state.roomId,
      tenant: this.props.tenant,
      currentUserId: this.currentUserId,
      browserInfo: this.state.browserInfo,
      appointmentInfo: useAppointments ? appointmentInfo : null
    });

    if (this.joiningRoomId) {
      this.appendMessage({
        text: "requestingToJoinConversation!",
        outbound: true,
        sentAt: new Date()
      });

      if (this.state.roomId) {
        this.socketEmit("client-requested-to-join-convo",{
          tenant: this.props.tenant,
          roomId: this.state.roomId,
          joinRoomId: this.joiningRoomId
        });
      }
    }
  };

  handleClientReply = (data) => {
    const { operatorUserInfo } = this.state;
    let replyUserInfo = !data.replyByBot ? data.operatorUserInfo : { name: null, photo: null };
    replyUserInfo = replyUserInfo || {};

    const newState = {
      messages: this.state.messages.concat([
        {
          outbound: data.fromSelf || false,
          text: data.reply || data.file_name,
          fileUrl: data.fileUrl,
          sentAt: new Date(),
          isBot: data.replyByBot,
          operatorUserName: replyUserInfo.name,
          operatorUserPhoto: replyUserInfo.photo
        }
      ]),
      numberOfActiveOperators: data.numberOfActiveOperators,
      operatorUserInfo: data.operatorUserInfo || operatorUserInfo
    };

    this.setState(newState, this.writeToLocalStorage({roomId: this.state.roomId, messages: newState.messages.slice(-MAX_OLD_MESSAGES_SAVED)}));
  };

  handleSocketError = (error) => {
    this.setState({
      error: "Serviço indisponível de momento. Por favor tente mais tarde."
    });
  };

  handleFinishConversation = () =>{
    this.setState({
      roomId:  null,
      messages: [
                  {
                    text: this.props.presentationMsg,
                    outbound: false,
                    sentAt: new Date(),
                    isBot: true
                  }
                ],
      isOpen: false,
      operatorUserInfo: null
    }, () => {
      this.writeToLocalStorage({});
      this.handleSocketConnect(false, '');
    })
  }

  handleUserInfo = data => {
    this.setState({
      userInfo: data,
      numberOfActiveOperators: data.numberOfActiveOperators
    });
  }

  // #region VideoChat
  handleVideoChatRequest = ({streamType}) => {
    // Tocar e vibrar
    if (!isSafari) {
      ringtone.play();
      window.navigator.vibrate && window.navigator.vibrate([2000,300,2000,300,2000,300,2000,300,2000,300,2000,300,2000,300,2000,300,2000]);
    }

    this.setState({
      waitingForVideo: true,
      boStreamType: streamType
    });
  }

  handleJoiningConvoNotAvailable = () => {
    this.debugLog("handleJoiningConvoNotAvailable");
    const newState = {
      messages: this.state.messages.concat([{text: "Este convite não é válido.\nO atendimento já poderá ter terminado ou o link poderá ser inválido."}])
    };

    this.setState(newState, () => this.writeToLocalStorage(
      {
        roomId: this.state.roomId,
        messages: newState.messages
      }
    ));
  }

  switchToDeviceClick = (_e, data) => {
    this.debugLog("switchToDeviceClick", data);

    if(data.deviceType == 'audioinput') {
      this.setState({audioInputDeviceId: data.deviceId});
    } else if (data.deviceType == 'videoinput') {
      this.setState({videoInputDeviceId: data.deviceId});
    }

    if(data.deviceType == 'audioinput' || data.deviceType == 'videoinput') {
      let { audioInputDeviceId, videoInputDeviceId } = this.state;

      if(data.deviceType == 'audioinput') {
        audioInputDeviceId = data.deviceId;
      } else {
        videoInputDeviceId = data.deviceId;
      }

      this.setState(
        {
          audioInputDeviceId: audioInputDeviceId,
          videoInputDeviceId: videoInputDeviceId
        },() => {
          const trackToReplace = this.state.localStream.getVideoTracks()[0];
          this.getLocalUserMedia().then(() => {
            this.debugLog("this.localVideoRef.current.srcObject 2", this.localVideoRef.current.srcObject);
            this.debugLog("this.state.localStream 2", this.state.localStream);

            this.videoCall.replaceTrack(trackToReplace, this.state.localStream.getVideoTracks()[0], this.state.localStream);
          });

          this.debugLog("this.localVideoRef.current.srcObject 1", this.localVideoRef.current.srcObject);
          this.debugLog("this.state.localStream 1", this.state.localStream);

        });
    } else { // audiooutputDeviceId
      const sinkId = data.deviceId;
      this.setState({ audioOutputDeviceId: data.deviceId });
      if (typeof this.localVideoRef.sinkId !== 'undefined') {
        this.localVideoRef.setSinkId(sinkId).then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);
        }).catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          this.setState({ audioOutputDeviceId: 0 });
        });
      } else {
        console.warn('Browser does not support output device selection.');
      }
    }
  }

  toggleAudioClick = () => {
    if(this.state.localStream.getAudioTracks().length > 0) {
      this.state.localStream.getAudioTracks().forEach(track => {
        track.enabled = !track.enabled;
      });
    }
    this.setState({
      micState: !this.state.micState
    })
  }

  toggleVideoClick = () => {
    if(this.state.localStream.getVideoTracks().length > 0) {
      this.state.localStream.getVideoTracks().forEach(track => {
        track.enabled = !track.enabled;
      });
    }
    this.setState({
      camState: !this.state.camState
    })
  }

  gotDevices = (mediaDevicesList) => {
    this.debugLog("gotDevices", mediaDevicesList);

    const { roomId, streamType } = this.state;


    this.setState({ mediaDevicesList: mediaDevicesList })

    this.getLocalUserMedia().then(async ({error, type}) => {
      this.setState({
        waitingForVideo: false,
        connectingVideo: true
      }, () => {
        this.socketEmit("client-accepted-video-chat", {
            tenant: this.props.tenant,
            currentUserId: this.currentUserId,
            roomId: roomId,
            streamType: type
          });

        const peer = this.videoCall.init(
          this.state.localStream,
          false
        );
        this.setState({ peer });

        peer.on('signal', (signal) => {
          this.debugLog("peer.on(signal)", signal);

          if (signal.renegotiate) return;

          this.setState({
              waitingForVideo: false
            },
            () => this.socketEmit("client-video-signal", {
              tenant: this.props.tenant,
              roomId: roomId,
              desc: signal
            })
          );
        });

        peer.on('stream', (stream) => {
          this.debugLog("peer.on(stream)", stream);
          const { remoteStreams } = this.state;

          this.setState({
            waitingForVideo: false,
            connectingVideo: false,
            videoChatConnected: true,
            remoteStreams: {
              ...remoteStreams,
              [stream.id]: stream
            }
          });
        });

        peer.on('error', (err) => {
          this.debugLog("peer.on(error)", err);

          if (err.toString().includes('reason=Close called')) {
            this.handleVideoChatEndSignal();
          } else {
            this.errorLog("Not treated peer error", err);
          }
        });
      });
    });
  };

  problemGettingDevices = (error) => {
    this.errorLog('problemGettingDevices', error);
  };

  getDisplayMediaForScreenSharing = () => {
    const { userIsStreaming } = this.state;

    return new Promise((resolve, reject) => {

      const constraints = {
        video: {
          cursor: 'always'
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true
        }
      };

      if(navigator.mediaDevices) {
        navigator.mediaDevices.getDisplayMedia(constraints).then(
          stream => {
            // Substituimos o video local pelo que estamos a partilhar
            this.state.localDisplayStream = this.localVideoRef.current.srcObject = stream;
            // Substituimos a track que estamos a enviar pelo screen share.
            this.videoCall.replaceTrack(this.state.localStream.getVideoTracks()[0], this.state.localDisplayStream.getVideoTracks()[0], this.state.localStream);

            // Quando alguém clica no botão de stop sharing
            stream.getVideoTracks()[0].onended = () => {
              console.log("Desligou?");
              this.localVideoRef.current.srcObject = this.state.localStream;

              this.videoCall.replaceTrack(this.state.localStream.getVideoTracks()[0], this.localVideoRef.current.srcObject.getVideoTracks()[0], this.state.localStream);
              this.setState({ userIsStreaming: false }); // Repor o state original
            };

            this.setState({ userIsStreaming: true });

            resolve({error: false, type: 'screen_share'});
          }).catch(
          (error) => {
            // Falhou a alteração do stream para screen share... o que fazer?
            this.errorLog("Falhou ao passar o stream para screen share", error);
            this.setState({ userIsStreaming: false }); // Repor o state original
          }
        );
      } else {
        this.errorLog("mediaDevices not accessible!")
        reject({error:true})
      }
    });
  }

  getLocalUserMedia = () => {
    this.debugLog("getLocalUserMedia");
    let { mediaDevicesList, audioInputDeviceId, videoInputDeviceId, audioOutputDeviceId, localStream } = this.state;

    return new Promise((resolve, reject) => {
      if(navigator.mediaDevices) {
        if (mediaDevicesList.length > 0) {
          if (videoInputDeviceId == 0){
            if (isDev) {
              if (this.joiningRoomId) {
                if (mediaDevicesList.find(elem => elem.kind == 'videoinput' && elem.label.startsWith("Integrated Webcam"))) {
                  videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput' && elem.label.startsWith("Integrated Webcam")).deviceId;
                } else {
                  videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput').deviceId;
                }
              } else {
                if (mediaDevicesList.find(elem => elem.kind == 'videoinput' && elem.label == "OBS Virtual Camera")) {
                  videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput' && elem.label == "OBS Virtual Camera").deviceId;
                } else {
                  videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput').deviceId;
                }
              }
            } else {
              videoInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'videoinput').deviceId;
            }
          }

          if (audioInputDeviceId == 0) {
            audioInputDeviceId = mediaDevicesList.find(elem => elem.kind == 'audioinput').deviceId;
          }

          if (mediaDevicesList.find(elem => elem.kind == 'audiooutput') && audioOutputDeviceId == 0) {
            audioOutputDeviceId = mediaDevicesList.find(elem => elem.kind == 'audiooutput').deviceId;
          }

          this.setState({
            audioInputDeviceId: audioInputDeviceId,
            videoInputDeviceId: videoInputDeviceId,
            audioOutputDeviceId: audioOutputDeviceId,
          });
        }

        let constraints = {
          audio: {
            deviceId: audioInputDeviceId ? {exact: audioInputDeviceId} : undefined
          },
          video: {
            width: { min: 160, ideal: 640, max: 1280 },
            height: { min: 120, ideal: 360, max: 720 },
            deviceId: videoInputDeviceId ? {exact: videoInputDeviceId} : undefined
          }
        };

        // Isto não funciona em firefox
        // navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
        //   if(permissionStatus.state != "granted"){
        //     this.setState({ askForMicPermission: true });
        //   }
        //   permissionStatus.onchange = ()=>{
        //     if(permissionStatus.state == "granted"){
        //       this.setState({ askForMicPermission: false });
        //     }
        //   }
        // });

        if (localStream.constructor.name == "MediaStream") {
          localStream.getTracks().forEach(track => {
            track.stop();
          });
        }

        navigator.mediaDevices.getUserMedia(constraints).then(
          stream => {
            this.setState({ localStream: stream, streamType: 'video', askForMicPermission: false });

            if (this.localVideoRef.current) {
              this.localVideoRef.current.srcObject = stream;
            }

            resolve({error: false, type: 'video'});
          }).catch((err) => {
            console.log("FALLBACK", err);
            delete constraints["video"]

            navigator.mediaDevices.getUserMedia(constraints).then(
              stream => {
                const canvas = document.getElementById('placeHolderCanvasAnimation');
                let dummyCanvasStream = canvas.captureStream();
                Object.assign(dummyCanvasStream.getVideoTracks()[0], {enabled: true});
                stream.addTrack(Object.assign(dummyCanvasStream.getVideoTracks()[0], {enabled: true}));

                this.setState({ localStream: stream, streamType: 'video', askForMicPermission: false });

                if (this.localVideoRef.current) {
                  this.localVideoRef.current.srcObject = stream;
                }

                resolve({ error: false, type: 'video' });
              }).catch(
              (error) => {
                this.errorLog("Segunda tentativa de getUserMedia deu erro", error);
                reject({ error:true })
              }
            );
          }
        );
      } else {
        this.errorLog("Sem acesso a mediaDevices");
        reject({ error:true })
      }
    });
  }

  acceptVideoChatClick = async () => {
    // Parar de tocar
    if (!isSafari) {
      ringtone.pause();
      window.navigator.vibrate && window.navigator.vibrate(0);
    }

    if(navigator.mediaDevices) {
      this.getLocalUserMedia().then(async ({error, type}) => {
        navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.problemGettingDevices);
      });
    }
  }

  shareScreenClick = () => {
    this.debugLog("shareScreenClick");
    const { roomId, streamType } = this.state;

    // Aqui dispara o ecrã de escolha de ecrâ a partilhar e substitui o stream local.
    this.getDisplayMediaForScreenSharing();
  }

  turnOffVideoChatClick = () => {
    this.debugLog("turnOffVideoChatClick");
    // Parar de tocar
    if (!isSafari) {
      ringtone.pause();
      window.navigator.vibrate && window.navigator.vibrate(0);
    }

    // Tocar
    if (!isSafari) {
      hangupRingtone.play();
    }

    this.socketEmit("client-turned-off-call",{
      tenant: this.props.tenant,
      roomId: this.state.roomId,
    });

    this.remoteStreamRefs = [];

    // duplicação de métodos? nope: o peer não existe ainda
    this.setState({
      askForMicPermission: false,
      waitingForVideo: false,
      connectingVideo: false,
      videoChatConnected: false,
      remoteStreams: {}
    }, () => {
      this.handleCallShutDown();
    });
  };

  rejectVideoChatClick = () => {
    // Parar de tocar
    if (!isSafari) {
      ringtone.pause();
      window.navigator.vibrate && window.navigator.vibrate(0);
    }

    // Tocar
    if (!isSafari) {
      hangupRingtone.play();
    }

    this.socketEmit("client-declined-call",{
      tenant: this.props.tenant,
      roomId: this.state.roomId,
    });

    this.remoteStreamRefs = [];

    // duplicação de métodos? nope: o peer não existe ainda
    this.setState({
      askForMicPermission: false,
      waitingForVideo: false,
      connectingVideo: false,
      videoChatConnected: false,
      remoteStreams: {}
    }, () => {
      this.handleCallShutDown();
    });
  }

  handleVideoChatEndSignal = () => {
    this.remoteStreamRefs = [];

    this.setState({
      askForMicPermission: false,
      waitingForVideo: false,
      connectingVideo: false,
      videoChatConnected: false,
      remoteStreams: {}
    }, () => {
      this.handleCallShutDown();
    });
    // Tocar
    if (!isSafari) {
      if(!ringtone.paused)
        ringtone.pause();
      hangupRingtone.play();
    }
  }

  handleCallShutDown = () => {
    if (this.state.localStream && this.state.localStream.constructor.name == "MediaStream") {
      this.state.localStream.getTracks().forEach(track => track.stop()); // parar as tracks todas
    }

    if (this.state.localDisplayStream && this.state.localDisplayStream.constructor.name == "MediaStream") {
      this.state.localDisplayStream.getTracks().forEach(track => track.stop()); // parar as tracks todas
    }

    if (this.state.peer && this.state.peer.destroy) {
      this.state.peer.destroy();
    }

    if (this.localVideoRef && this.localVideoRef.current) {
      this.localVideoRef.current.srcObject = null;
    }

    this.setState({ localStream: {}, peer: {} });
  };

  //#endregion VideoChat

  setupSocket = () => {
    this.debugLog("setupSocket");
    if (this.socket) {
      return;
    }

    this.socket = io(`${this.props.apiUrl}/client`, { path: "" });

    this.socket.on("connect",() => {
      this.logSocketOn("connect");
      this.handleSocketConnect(true);
    });

    this.socket.on("client-token", (data) => {
      this.logSocketOn("client-token", data);
      // this.setState( { error: null }, this.storeSocketToken(data));

      this.setState(
        {
          error: null,
          roomId: data.roomId,
          messages: this.state.messages.slice(-MAX_OLD_MESSAGES_SAVED)
        }, () => {
          this.writeToLocalStorage({roomId: data.roomId, messages: this.state.messages.slice(-MAX_OLD_MESSAGES_SAVED)});

          if(this.joiningRoomId) {
            this.socketEmit("client-requested-to-join-convo",{
              tenant: this.props.tenant,
              roomId: this.state.roomId,
              joinRoomId: this.joiningRoomId
            });
          }
        }
      );
    });

    this.socket.on("client-reply", (data) => {
      this.logSocketOn("client-reply", data);
      this.handleClientReply(data);
    });

    this.socket.on("connect_error", (error) => {
      this.logSocketOn("connect_error");
      this.handleSocketError(error);
    });
    this.socket.on("client-user-info", (data) => {
      this.logSocketOn("client-user-info");
      this.handleUserInfo(data);
    });

    this.socket.on("client-new-responsable-for-conversation", (data) => {
      this.logSocketOn("client-new-responsable-for-conversation", data);
      this.setState({operatorUserInfo: data.operatorUserInfo});
    });

    this.socket.on("client-set-available-operators", ({numberOfActiveOperators}) => {
      this.logSocketOn("client-set-available-operators", {numberOfActiveOperators});
      this.setState({numberOfActiveOperators});
    });

    this.socket.on("backoffice-asked-to-start-video-chat", (data) => {
      this.logSocketOn("backoffice-asked-to-start-video-chat", data);
      this.handleVideoChatRequest(data);
    });

    this.socket.on("backoffice-said-invitation-no-longer-available", () => {
      this.logSocketOn("backoffice-said-invitation-no-longer-available");
      this.handleJoiningConvoNotAvailable();
    });

    this.socket.on("backoffice-video-desc", (data) => {
      this.logSocketOn("backoffice-video-desc", data);
      this.videoCall.connect(data);
    });

    this.socket.on("backoffice-ended-video-chat", () => {
      this.logSocketOn("backoffice-ended-video-chat");
      this.handleVideoChatEndSignal();
    });

    this.socket.on("client-close-conversation", () => {
      this.logSocketOn("client-close-conversation");
      this.handleFinishConversation();
    });

    this.socket.on("client-remove-stream", (data) => {
      this.logSocketOn("client-remove-stream", data);
      const { remoteStreams } = this.state;
      delete remoteStreams[data.streamId];
      this.setState({
        remoteStreams
      });
    });

    this.socket.on("client-set-area", ({area}) => {
      this.logSocketOn("client-set-area", {area});
      this.setState({area})
    });
  };

  componentDidMount() {
    this.debugLog("componentDidMount");

    this.debugLog("state", this.state);
    this.debugLog("this.socket", this.socket);

    if (this.state.isOpen && (!this.socket || this.socket == null || this.socket == undefined)) {
      this.debugLog("vai chamar");
      this.setupSocket();
      this.debugLog("chamou");
    }

    // * Esperar e ocultar a mensagem de chat pré-definida da primeira vez
    if (!localStorage.chat_alerted) {
      this.setState({
        isHovering: true
      });
      setTimeout(() => {
        this.setState({
          isHovering: false
        });
        localStorage.setItem('chat_alerted', true);
      }, 7500);
    }
    // * Primeiro fazemos o detect do que queremos do utilizador
    const currentPage = window.location.href;
    const userAgent = detect.parse(navigator.userAgent);
    const scrollPosition = document.documentElement.scrollTop;

    setTimeout(() => {
      this.setState({
        isHovering: false
      });
      localStorage.setItem('chat_alerted', true);
    }, 0);

    this.setState(
      {
        browserInfo: {
          userAgent,
          currentPage,
          scrollPosition
        }
      },
      () => {
        if(this.props.appointmentInfo)
          this.openChatModalClick();
      }
    );
  }

  render() {
    const { botName, imageUrl, slabHello, slabIntro, terms, hasFileUpload } = this.props;
    const {
      isOpen,
      isHovering,
      operatorUserInfo,
      waitingForVideo,
      connectingVideo,
      videoChatConnected,
      mediaDevicesList,
      audioInputDeviceId,
      audioOutputDeviceId,
      videoInputDeviceId,
      camState,
      micState,
      streamType,
      boStreamType,
      userIsStreaming,
      roomId,
      numberOfActiveOperators,

      remoteStreams
    } = this.state;

    const hasTerms = terms && terms != "{}";
    let pose = "closed";

    if (isOpen) {
      pose = "open";
    } else if (isHovering) {
      pose = "hovered";
    }

    let contextTrigger = null;

    const toggleMenuClick = e => {
      if(contextTrigger) {
        contextTrigger.handleContextClick(e);
      }
    };

    const transitionStyles = {
      entering: { opacity: 1 },
      entered:  { opacity: 1 },
      exiting:  { opacity: 0 },
      exited:  { opacity: 0 },
    };

    return (
      <ThemeProvider theme={this.props.theme}>
        <AppContext.Provider
          value={{
            ...this.state,
            imageUrl: imageUrl,
            botName: botName,
            openChatModalClick: this.openChatModalClick,
            onMouseHover: this.onMouseHover,
            appendMessage: this.appendMessage,
            appendFile: this.appendFile,
            apiUrl: this.props.apiUrl,
            isOpen: this.state.isOpen,
            hasFileUpload: hasFileUpload
          }}
        >
          <Container
            className={
              (waitingForVideo || connectingVideo || videoChatConnected)
              && streamType == 'video'
                ? 'has-video'
              : isOpen ? 'container-open' : ''
            }
          >
            <Slab pose={pose}>
              <span className={isOpen ? "close-btn" : "close-btn hide"} onClick={this.openChatModalClick}>
                <FontAwesomeIcon icon={faTimes} color="#546269" />
              </span>
              {isOpen &&
                <ChatHeader className={operatorUserInfo && operatorUserInfo.name ? "auto-margin" : "no-margin"}>
                  <div style={{ display: 'none'}}>
                    <PlaceHolderCanvasAnimation text={ this.joiningRoomId ? "Convidado" : "Cliente" }></PlaceHolderCanvasAnimation>
                  </div>
                  <div className="header-picture">
                    <img
                      src={operatorUserInfo && operatorUserInfo.photo ?
                        operatorUserInfo.photo
                      : !!imageUrl
                        ? imageUrl
                        : "https://image.flaticon.com/icons/svg/682/682037.svg"
                      }
                    />
                  </div>
                  <div className="header-name">
                    {operatorUserInfo && operatorUserInfo.name ?
                      <span>{operatorUserInfo.name}</span>
                    : <span>{botName}</span>
                    }
                  </div>
                </ChatHeader>
              }

              <PoseGroup animateOnMount={true}>
                {isOpen && waitingForVideo && this.joiningRoomId &&
                  <VideoConfirmation key="1">
                    <span>
                      <span className="operator-name">{operatorUserInfo.name}</span>
                      <div className="operator-pic-container">
                        <div className="operator-pic"><img src={operatorUserInfo.photo} alt="Operador" /></div>
                        <div className="call-signal"></div>
                      </div>
                      <span className="main">Juntar-se à chamada a decorrer?</span>
                      <span className="descriptive">Conceda as permissões necessárias para usar <strong>a câmara e o microfone</strong> para se juntar à conversa para a qual foi convidado.</span>
                      <div className="actions-container">
                        <span onClick={this.acceptVideoChatClick} className="chat-action accept">
                          <FontAwesomeIcon icon={faPhone} /> Juntar-se
                        </span>&nbsp;&nbsp;
                        <span onClick={this.rejectVideoChatClick} className="chat-action reject">
                          <FontAwesomeIcon icon={faPhoneSlash} /> Cancelar
                        </span>
                      </div>
                    </span>
                  </VideoConfirmation>
                }
                {isOpen && waitingForVideo && this.joiningRoomId == undefined &&
                  <VideoConfirmation key="1">
                    <span>
                      <span className="operator-name">{operatorUserInfo.name}</span>
                      <div className="operator-pic-container">
                        <div className="operator-pic"><img src={operatorUserInfo.photo} alt="Operador" /></div>
                        <div className="call-signal"></div>
                      </div>
                      <span className="main">Realizar chamada com o operador?</span>
                      <span className="descriptive">Conceda as permissões necessárias para usar <strong>a câmara e o microfone</strong> para falar com o operador.</span>
                      <div className="actions-container">
                        <span onClick={this.acceptVideoChatClick} className="chat-action accept">
                          <FontAwesomeIcon icon={faPhone} /> Atender
                        </span>&nbsp;&nbsp;
                        <span onClick={this.rejectVideoChatClick} className="chat-action reject">
                          <FontAwesomeIcon icon={faPhoneSlash} /> Recusar
                        </span>
                      </div>
                    </span>
                  </VideoConfirmation>
                }
              </PoseGroup>
              {connectingVideo &&
                <VideoConfirmation className="is-connecting">
                  <span>
                    <span className="operator-name">{operatorUserInfo.name}</span>
                    <div className="operator-pic"><img src={operatorUserInfo.photo} alt="Operador" /></div>
                    <span className="main">Realizar chamada com o operador?</span>
                    <span className="descriptive">Conceda as permissões necessárias para usar <strong>a câmara e o microfone</strong> para falar com o operador.</span>
                    <span className="status">A estabelecer ligação...</span>
                  </span>
                </VideoConfirmation>
              }

              {isOpen && !(operatorUserInfo && operatorUserInfo.name) &&
                <ActiveUsers>
                  {numberOfActiveOperators == 0 ?
                    <span>
                      <IconContainer className="unavailable"><FontAwesomeIcon icon={faExclamationCircle} /></IconContainer>
                      Não existem operadores disponíveis neste momento.&nbsp;
                      <span
                        onClick={() => this.appendMessage({
                                                        text: "operador",
                                                        outbound: true,
                                                        sentAt: new Date()
                                                      })}
                        className="link unavailable"
                      >
                        Enviar uma mensagem
                      </span>
                    </span>
                  :
                    numberOfActiveOperators == 1 ?
                      <span>
                        <IconContainer><FontAwesomeIcon icon={faUserCircle} /></IconContainer>
                        Existe um operador disponível neste momento.&nbsp;
                        <span className="link"
                          onClick={() => this.appendMessage({
                                                        text: "operador",
                                                        outbound: true,
                                                        sentAt: new Date()
                                                      })}
                        >
                          Falar com o operador
                        </span>
                      </span>
                    :
                      <span>
                        <IconContainer><FontAwesomeIcon icon={faUserCircle} /></IconContainer>
                        Existem {numberOfActiveOperators} operadores disponíveis neste momento.&nbsp;
                        <span className="link"
                          onClick={() => this.appendMessage({
                                                        text: "operador",
                                                        outbound: true,
                                                        sentAt: new Date()
                                                      })}
                        >
                          Falar com um operador
                        </span>
                      </span>
                  }
                </ActiveUsers>
              }
              <VideoChatContainer
                className={`
                  ${videoChatConnected ? 'connected' : ''}
                  ${userIsStreaming ? 'userIsStreaming' : ''}
                `}
              >
                {(waitingForVideo || connectingVideo || videoChatConnected) && streamType=='video' &&
                  <React.Fragment>
                    <video
                      style={{ maxHeight: '90px' }}
                      autoPlay
                      id='localVideo'
                      height='100%'
                      width='100%'
                      muted
                      playsInline
                      ref={this.localVideoRef}
                    />
                    {userIsStreaming && <span className="infoLabel">O meu ecrã</span>}
                  </React.Fragment>
                }
                {videoChatConnected && boStreamType == 'video' &&
                  <div className="remote-videos">
                    { Object.entries(remoteStreams).map((stream, index) => {
                      return (
                        <video
                          style={{ visibility: 'visible', maxHeight: '500px' }}
                          autoPlay
                          height='100%'
                          width='100%'
                          controls
                          muted={isSafari}
                          playsInline
                          id={`remoteVideo_${stream[0]}`}
                          key={`remoteVideo_${stream[0]}`}
                          ref={elem => this.remoteStreamRefs[index] = elem}
                        />
                        );
                    })}
                  </div>
                }

                {/* Acções durante a chamada */}
                {(waitingForVideo || connectingVideo || videoChatConnected) &&
                  <React.Fragment>

                    {/* Botões de desligar chamada & partilhar o ecrã */}
                    <CallButtonContainer>
                      <UserShare
                        onClick={this.shareScreenClick}
                        title={'Partilhar ecrã'}
                        className={userIsStreaming && 'userIsStreaming'}
                      >
                        <FontAwesomeIcon icon={faDesktop} /> {userIsStreaming ? 'Partilha ativada' : 'Partilhar ecrã'}
                        <BetaTag>BETA</BetaTag>
                      </UserShare>
                      <UserHangUp
                        onClick={this.turnOffVideoChatClick}
                        title={'Desligar chamada'}
                      >
                        <FontAwesomeIcon icon={faPhoneSlash} /> Desligar
                      </UserHangUp>
                    </CallButtonContainer>

                    {/* Controlos para desligar câmara e/ou microfone */}
                    <LocalVideoControls className={videoChatConnected && 'connected'}>
                      {streamType=='video' && (
                        <button
                          style={{borderRadius: '4px 0 0 4px'}}
                          onClick={this.toggleVideoClick}
                          title={camState ? 'Desligar Video' : 'Ligar Video'}>
                          {
                            camState ? (
                              <FontAwesomeIcon icon={faVideo} />
                            ):(
                              <FontAwesomeIcon icon={faVideoSlash} />
                            )
                          }
                        </button>
                      )}

                      {/* Este statement fabulosamente complicado serve para dar o estilo certo aos botões 😎 */}
                      <button
                        style={
                          streamType=='audio'
                            ? waitingForVideo
                              ? {borderRadius: '4px 0 0 4px'}
                              : {borderRadius: '4px'}
                            : waitingForVideo
                              ? {borderRadius: '0'}
                              : {borderRadius: '0 4px 4px 0'}
                        }
                        onClick={this.toggleAudioClick}
                        title={micState ? 'Desligar Microfone' : 'Ligar Microfone'}>
                        {
                          micState ? (
                            <FontAwesomeIcon icon={faMicrophone} />
                          ):(
                            <FontAwesomeIcon icon={faMicrophoneSlash} />
                          )
                        }
                      </button>
                    </LocalVideoControls>

                    {/* Escolha de dispositivos de input, output e vídeo */}
                    <LocalVideoConfig>
                      <ContextMenuTrigger id="local-video-sources" ref={c => contextTrigger = c}>
                        <button
                          onClick={toggleMenuClick}
                          title='Configurações'
                        >
                          <FontAwesomeIcon icon={faCog} />
                          <span>Dispositivos</span>
                        </button>
                      </ContextMenuTrigger>
                      <ContextMenu id="local-video-sources">
                        {mediaDevicesList.map((item, i) => {
                          return [
                            (i > 0 && mediaDevicesList[i-1].kind != mediaDevicesList[i].kind &&
                              <React.Fragment>
                                <MenuItem divider />
                              </React.Fragment>
                            ),
                            <MenuItem
                              key={item.groupId}
                              data={{
                                deviceType: item.kind,
                                deviceId: item.deviceId
                              }}
                              onClick={this.switchToDeviceClick}
                            >
                              {item.kind == 'audioinput' && (item.deviceId == audioInputDeviceId ?
                                <React.Fragment>
                                  <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faMicrophone} />
                                  <span><strong>{item.label}</strong></span>
                                </React.Fragment>
                                : <span>{item.label}</span>
                              )}
                              {item.kind == 'videoinput' && (item.deviceId == videoInputDeviceId ?
                                <React.Fragment>
                                  <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faVideo} />
                                  <span><strong>{item.label}</strong></span>
                                </React.Fragment>
                                : <span>{item.label}</span>
                              )}
                              {item.kind == 'audiooutput' && (item.deviceId == audioOutputDeviceId ?
                                <React.Fragment>
                                  <FontAwesomeIcon style={{ opacity: 0.8 }} icon={faVolumeUp} />
                                  <span><strong>{item.label}</strong></span>
                                </React.Fragment>
                                : <span>{item.label}</span>
                              )}
                            </MenuItem>
                          ];
                        })}
                      </ContextMenu>
                    </LocalVideoConfig>
                  </React.Fragment>
                }
              </VideoChatContainer>
              {isHovering && !isOpen && (
                <React.Fragment>
                  <SlabTitle>{slabHello}</SlabTitle>
                  <SlabSubTitle>{slabIntro}</SlabSubTitle>
                </React.Fragment>
              )}{isOpen && hasTerms && roomId &&(
                <Terms
                  apiUrl={this.props.apiUrl}
                  tenant={this.props.tenant}
                  numberOfActiveOperators={numberOfActiveOperators}
                  imageUrl={imageUrl}
                  botName={botName}
                  hasFileUpload={hasFileUpload}
                  roomId={roomId}
                  terms={terms}
                />
              )}
              {isOpen && (
                <Messages
                  numberOfActiveOperators={numberOfActiveOperators}
                  imageUrl={imageUrl}
                  botName={botName}
                />
              )}
              {isOpen && <Form />}
            </Slab>

            <Avatar
              pose={pose}
              alt="Chatbot"
              src={
                !!imageUrl
                  ? imageUrl
                  : "https://image.flaticon.com/icons/svg/682/682037.svg"
              }
            />
          </Container>
          {this.state.askForMicPermission && (
            <div className="permission-overlay" style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "space-between",
              justifyContent: "center",
              color: "white",
              fontSize: "1rem",
              position: "fixed",
              padding: "15px 30px",
              textAlign: "center",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "radial-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.5))",
              backdropFilter: "blur(5px) saturate(130%)",
              zIndex: 99999}}>
                <div
                  style= {{
                    width: "85px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "2rem",
                    color: "rgba(255,255,255,0.85)",
                    margin: "10px auto"
                  }}
                >
                  <FontAwesomeIcon icon={faVideo} />
                  <FontAwesomeIcon icon={faMicrophone} />
                </div>
                <h3>Permitir acesso à câmara e ao microfone?</h3>
                <p style={{ marginTop: "-5px" }}>
                  Para poder realizar a chamada, conceda as permissões para utilizar a câmara e o microfone do seu dispositivo.
                </p>
              </div>
          )}
        </AppContext.Provider>
      </ThemeProvider>
    );
  }
}
