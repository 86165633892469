import React, { Component } from "react";

import PlaceHolderCanvas from "./PlaceHolderCanvas";

export default class PlaceHolderCanvasAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = { textSize: 30, tick: 1 };
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount() {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  updateAnimationState() {
    this.setState((prevState) => ({
      textSize: prevState.textSize + prevState.tick,
      tick: (prevState.textSize + prevState.tick > 40 || prevState.textSize + prevState.tick < 30) ? prevState.tick * -1 : prevState.tick,
    }));
    setTimeout(() => {
      this.rAF = requestAnimationFrame(this.updateAnimationState);
    }, 50);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rAF);
  }

  render() {
    return <PlaceHolderCanvas textSize={this.state.textSize} text={this.props.text} />
  }
}
