import React from "react";
import posed, { PoseGroup } from "react-pose";
import styled from "styled-components";

import { AppContext } from "./index.js";

const ConnError = posed.div({
  enter: { bottom: 80, opacity: 1 },
  exit: { bottom: -10, opacity: 0 }
});

const Container = styled(ConnError)`
  z-index: 20;
  width: 100%;
  position: absolute;
  padding: 0px 20px 0px 20px;
`;

const Error = styled.div`
  padding: 8px 10px;
  border-radius: 6px;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.alertColor};
`;

export default ({ children }= this.props) => (
  <AppContext.Consumer>
    {context => {
      if (!context.isOpen) {
        return null;
      }

      return (
        <PoseGroup>
          {context.error && (
            <Container key="connect-error">
              <Error>{children}</Error>
            </Container>
          )}
        </PoseGroup>
      );
    }}
  </AppContext.Consumer>
);
