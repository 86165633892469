import Peer from 'simple-peer'
export default class VideoCall {
  peer = null
  init = (stream, initiator) => {
    this.peer = new Peer({
      initiator: initiator,
      stream: stream,
      trickle: false,
      reconnectTimer: 1000,
      iceTransportPolicy: 'relay',
      offerConstraints: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      },
      config: {
        iceServers: [
          { urls: ['stun:stun.eurescom.eu:3478'] },
          {
              urls: [
                "turn:eu-turn5.xirsys.com:80?transport=udp",
                "turn:eu-turn5.xirsys.com:3478?transport=udp",
                "turn:eu-turn5.xirsys.com:80?transport=tcp",
                "turn:eu-turn5.xirsys.com:3478?transport=tcp",
                "turns:eu-turn5.xirsys.com:443?transport=tcp",
                "turns:eu-turn5.xirsys.com:5349?transport=tcp"
              ],
              username: "2g4OjzFmMvUCuB8NpYIEReo7LuUBba09l3o6BC5j07iQSdX4uar89SSag1WwLUDJAAAAAF-ZMBV3aXJlbWF6ZQ==",
              credential: "2f468802-18fa-11eb-b2c6-0242ac140004"
          }
        ]
      }
    })
    return this.peer
  }

  replaceTrack = (oldTrack, newTrack, stream) => {
    console.log("Substituindo uma track", oldTrack, newTrack);
    // this.peer.replaceTrack(oldTrack, newTrack, stream);
    this.peer.replaceTrack(oldTrack, newTrack, this.peer.streams[0]);
  }

  addStream = (stream) => {
    console.log("A adicionar um stream", stream);
    this.peer.addStream(stream);
  }

  removeStream = (stream) => {
    console.log("A remover um stream", stream);
    this.peer.removeStream(stream);
  }

  connect = (otherId) => {
    this.peer.signal(otherId)
  }
}