const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  // "ngrok-skip-browser-warning": true Descomentar isto quando estivermos a utilizar o ngrok
};

const get = (url, options = {}) => {
  return request(url, options);
};

const post = (url, options = {}) => {
  return request(url, { method: "POST", ...options });
};

const request = (url, options = {}) => {
  const { method, headers, ...rest } = options;

  return fetch(url, {
    ...rest,
    method,
    headers: headers || commonHeaders
  });
};

export { get, post, commonHeaders };
